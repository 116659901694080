<!--  -->
<template>
  <div class="matters">
    <div class="title-nav mb20" v-if="!isnomatters && !detailload">
      <div class="mb20 title">总部工作事项</div>
      <div class="title-item mb20">
        <div class="title-text">标题：</div>
        <div class="title-label">{{ workinfo.title }}</div>
      </div>
      <div class="title-item mb20">
        <div class="title-text">截止时间：</div>
        <div class="title-label">{{ workinfo.dateLine }}</div>
      </div>
      <div class="title-item mb20">
        <div class="title-text">整改年份：</div>
        <div class="title-label">{{ workinfo.year }}</div>
      </div>
      <div class="title-item mb20">
        <div class="title-text">整改月份：</div>
        <div class="title-label">{{ workinfo.month }}</div>
      </div>
    </div>
    <div class="title-nav table-wp" v-if="!isnomatters && !detailload">
      <div class="table-wrap mb20">
        <div class="title">
          完成进度（{{ workinfo.completedNum }}/{{ workinfo.totalNum }})
        </div>
        <div @click="downloadtemp" class="download-btn">
          <!-- <img class="common-img" src="@/assets/download.png" alt="" /> -->
          <i class="el-icon-download common-img"></i>
          导出数据明细
        </div>
      </div>

      <div class="soped">
        <table>
          <thead>
            <tr>
              <th>姓名</th>
              <th>整改状态</th>
              <th>最新整改时间</th>
            </tr>
          </thead>
          <tbody v-show="(workinfo.schedules || []).length > 0">
            <tr
              v-for="(item, index) in workinfo.schedules || []"
              :key="index"
              class="trwrap"
            >
              <td>{{ item.name }}</td>
              <td
                class="status-text"
                :class="[item.stateStr === '未提交整改' ? 'statusred' : '']"
              >
                {{ item.stateStr }}
              </td>
              <td>
                {{ item.rectificationTime || "--" }}
              </td>
            </tr>
            <tr class="empty-tag" v-if="!(workinfo.schedules || []).length">
              暂无数据
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="nomatter-empty" v-show="isnomatters && !detailload">
      <img
        src="https://dse-wechat-prod-file-cdn.digitalvolvo.com/Material/202410/29/e860e0b7eefd4fd5b5256a55d91166e2.png"
        mode="scaleToFill"
        class="empty-img"
      />
      <div>工单不存在</div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api.js";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      id: "",
      password: "",
      workinfo: {},
      downloading: false,
      isnomatters: false,
      detailload: false,
    };
  },
  // 方法集合
  methods: {
    downloadtemp() {
      this.$confirm("确认导出工单数据后，通过邮件发送到您的邮箱吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "matterconfirm",
        type: "warning",
      })
        .then(async () => {
          try {
            if (this.downloading) {
              return;
            }
            this.downloading = true;
            const url =
              Api.apiDomain + `/ma/headMatter/matter/schedule/send/email`;
            const { data: res } = await this.$http.post(url, {
              id: this.id,
              password: this.password,
            });
            if (res.code === 0) {
              this.$message.success({
                message: "导出数据明细成功",
                customClass: "matterconfirm",
              });
            } else {
              this.$message.warning({
                message: "导出失败",
                customClass: "matterconfirm",
              });
            }
          } catch (error) {
            this.$message.warning({
              message: "导出失败",
              customClass: "matterconfirm",
            });
          } finally {
            this.downloading = false;
          }
        })
        .catch(() => {});
    },
    async getdetail() {
      try {
        this.detailload = true;
        const url = Api.apiDomain + `/ma/headMatter/matter/schedule`;
        const { data: res } = await this.$http.post(url, {
          id: this.id,
          password: this.password,
        });
        if (res.code === 0) {
          this.workinfo = res.data;
        } else {
          this.isnomatters = true;
        }
      } catch (error) {
        this.isnomatters = true;
      } finally {
        this.detailload = false;
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.id = this.$route.query.id;
    this.password = this.$route.query.password;
    this.getdetail();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style scoped lang="less">
.matters {
  padding: 20px;
  background: #f6f8fa;
  min-height: 100vh;
  box-sizing: border-box;
}
.title-nav {
  padding: 20px;
  background: #fff;
}
.mb20 {
  margin-bottom: 15px;
}
.title {
  height: 26px;
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.92);
}
.title-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.92);
}
.title-text {
  color: #666;
  width: 140px;
}
.table-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-img {
  /* vertical-align: sub; */
  top: 1px;
  /* margin-right: 3px; */
  position: relative;
}
.download-btn {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  height: 32px;
  background: #3d7efe;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
}

table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  word-break: break-word;
  tr {
    height: 48px;
  }
  td,
  th {
    border: 1px solid #eeeeee;
    border-bottom: none;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.92);
  }
  .trwrap:last-child {
    td {
      border-bottom: 1px solid #eeeeee;
      // border-top: none;
    }
  }
  th {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.92);
    // border-bottom: 1px solid #eeeeee;
  }
  td {
    padding: 8px;
  }
}
table thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  // border-bottom: 1px solid #eeeeee;
}
.table-wp {
  padding-bottom: 60px;
}
.status-text {
  color: rgba(0, 181, 120, 1);
  &.statusred {
    color: rgba(250, 81, 81, 1);
  }
}
.empty-tag {
  // padding: 30px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.92);
  border: 1px solid #eeeeee;
}
.nomatter-empty {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.56);
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style>
.matterconfirm.el-message-box {
  width: 330px;
}
.matterconfirm.el-message {
  min-width: 350px;
}
</style>
