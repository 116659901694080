import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/Login.vue';
import Home from '../components/Home.vue';
import Welcome from '../components/Welcome.vue';
import Users from '../components/user/Users.vue';
import Dealers from '../components/dealer/Dealers.vue';
import Areas from '../components/area/Areas.vue';
import Specials from '../components/special/Specials.vue';
import SmallArea from '../components/area/SmallArea.vue';
import SpecialContents from '../components/special/SpecialContents.vue';
import Version from '../components/version/Version.vue';
import SelfCheck from '../components/selfCheck/SelfCheck.vue';
import Checks from '@/components/checks/Checks.vue';
import Address from '@/components/address/Address.vue';
import Banners from '../components/banner/Banners.vue';
import Emails from '../components/email/Emails.vue';
import EmailRole from '../components/email/EmailRole.vue';
import Calender from '../components/report/Calender.vue';
import CheckIn from '../components/report/CheckIn.vue';
import Preset from '@/components/preset/Preset.vue';
import RectifyMatter from '@/components/rectifyMatter/RectifyMatter';
import SupportMatter from '@/components/supportMatter/SupportMatter';
import Personalized from '@/components/personalized/Personalized';
import HeadMatter from '@/components/headMatter/HeadMatter';
import CheckInsForCar from '@/components/report/CheckInsForCar';
import CheckInsForQuality from '@/components/report/CheckInsForQuality';
import CheckInsForOFP from '@/components/report/CheckInsForOFP';
import CheckInsForDirect from '@/components/report/CheckInsForDirect';
import Questionnaire from '@/components/questionnaire/questionnaire';
import SetQuestionnaire from '@/components/questionnaire/setQuestionnaire';
import workmatter from '@/components/workmatter/workmatter';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/workmatter',
        component: workmatter
    },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            {
                path: '/welcome', component: Welcome
            },
            {
                path: '/users', component: Users
            },
            {
                path: '/dealers', component: Dealers
            },
            {
                path: '/areas', component: Areas
            },
            {
                path: '/smallArea', component: SmallArea
            },
            {
                path: '/specials', component: Specials
            },
            {
                path: '/specialContents', component: SpecialContents
            },
            {
                path: '/versions', component: Version
            },
            {
                path: '/headMatter', component: HeadMatter
            },
            {
                path: '/selfChecks', component: SelfCheck
            },
            {
                path: '/checks', component: Checks
            },
            {
                path: '/preset', component: Preset
            },
            {
                path: '/supportMatter', component: SupportMatter
            },
            {
                path: '/personalized', component: Personalized
            },
            {
                path: '/rectifyMatter', component: RectifyMatter
            },
            {
                path: '/address', component: Address
            },
            {
                path: '/banners', component: Banners
            },
            {
                path: '/emails', component: Emails
            },
            {
                path: '/emailRole', component: EmailRole
            },
            {
                path: '/calenders', component: Calender
            },
            {
                path: '/checkIns', component: CheckIn
            },
            {
                path: '/checkInsForCar', component: CheckInsForCar
            },
            {
                path: '/checkInsForQuality', component: CheckInsForQuality
            },
            {
                path: '/checkInsForOFP', component: CheckInsForOFP
            },
            {
                path: '/checkInsForDirect', component: CheckInsForDirect
            },
            {
                path: '/questionnaire', component: Questionnaire
            },
            {
                path: '/setQuestionnaire', component: SetQuestionnaire
            }
        ]
    }
];

const router = new VueRouter({
    routes
});
const whitelist = ['/workmatter'];
router.beforeEach((to, from, next) => {
    console.log(to, '=----')
    // to将要访问的路径
    // from代表从哪个路径跳转而来
    // next是一个函数，表示分耐性
    // next() 放行， next('/login')强制跳转
    if (whitelist.includes(to.path)) {
        next();
    } else {
        if (to.path === '/login') {
            return next();
        }
        const tokenStr = window.sessionStorage.getItem('token');
        if (!tokenStr) {
            return next('/login');
        }
        next();
    }
});

export default router;
