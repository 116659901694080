export let Api = {
    apiDomain: 'https://dcssapi.digitalvolvo.com', // 域名
    ossDomain: 'https://dcss-file-cdn.digitalvolvo.com' // 生产文件前缀域名
}
const hostname = window.location.hostname
console.log(process.env.NODE_ENV, 'process.env.NODE_ENV')
if (process.env.NODE_ENV === 'development') {
    /** 测试环境 */
    Api = {
        apiDomain: 'https://dcssapi-new-uat.digitalvolvo.com', // 域名
        ossDomain: 'https://dcss-file-cdn-uat.digitalvolvo.com' // 文件前缀域名
    };
} else {
    if (hostname === 'dcssweb-new-uat.digitalvolvo.com') {
        /** 测试环境 */
        Api = {
            apiDomain: 'https://dcssapi-new-uat.digitalvolvo.com', // 域名
            ossDomain: 'https://dcss-file-cdn-uat.digitalvolvo.com' // 文件前缀域名
        };
        console.log('uat环境')
    } else {
        /** 生产环境 */
        Api = {
            apiDomain: 'https://dcssapi.digitalvolvo.com', // 域名
            ossDomain: 'https://dcss-file-cdn.digitalvolvo.com' // 生产文件前缀域名

        };
        console.log('生产')
    }
}