<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>总部事项管理</el-breadcrumb-item>
      <el-breadcrumb-item>总部事项列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.year"
            placeholder="请选择年份"
            @change="selectYear"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.month"
            placeholder="请选择月份"
            @change="getHeadMatterList"
          >
            <el-option
              v-for="item in monthList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="success" @click="addRecord">下发总部事项</el-button>
          <!-- <el-button type="info" @click="exportDialogVisible = true"> 导入专项整改</el-button> -->
        </el-col>
      </el-row>
      <el-table :data="headMatterContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="整改年份" prop="year"></el-table-column>
        <el-table-column label="整改月份" prop="month"></el-table-column>
        <el-table-column
          width="160px"
          label="截止时间"
          prop="deadline"
        ></el-table-column>
        <el-table-column
          width="200px"
          label="整改标题"
          prop="title"
        ></el-table-column>
        <el-table-column width="200px" label="整改内容" prop="content">
        </el-table-column>
        <el-table-column
          label="问卷名称"
          width="160px"
          prop="questionName"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="问卷填写人数">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                填写人数：{{ scope.row.nameCnCount }}<br />发放人数：{{
                  scope.row.answerCount
                }}
              </div>
              <div>
                <span style="color: #409eff">{{ scope.row.nameCnCount }}</span
                >/<span>{{ scope.row.answerCount }}</span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="经销商" prop="dealerCodes"></el-table-column>
        <el-table-column label="小区经理" prop="nameCns"></el-table-column>
        <el-table-column
          label="市场经理"
          prop="marketManager"
        ></el-table-column>
        <el-table-column width="180px">
          <div slot="header">
            工单关注人
            <el-tooltip placement="top">
              <div slot="content">
                <p>工单关注人工单进度邮件提醒设置：</p>
                <p>·工单添加提醒：每次新增的工单关注人会收到邮件通知。</p>
                <p>·截止日期提醒：在工单截止日期收到邮件提醒。</p>
              </div>
              <img src="@/assets/tip.png" alt="" class="va-s" />
            </el-tooltip>
          </div>
          <template slot-scope="scope">
            <div class="emaile-wrap">
              <div class="email-item">
                {{ (scope.row.followEmails || []).join(" , ") }}
              </div>
              <i
                class="el-icon-edit edit-wrap"
                @click="handeditemail(scope.row)"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="导出数据" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="exportData(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      title="模板导入"
      :center="true"
      :visible.sync="exportDialogVisible"
      width="30%"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeUpload"
        :limit="1"
        action=""
        :on-exceed="handleExceed"
        :http-request="uploadSpecial"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button type="text" @click="downLoadTempalte"
        >下载导入总部事项模板</el-button
      >

      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          element-loading-text="系统下发中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="下发总部事项"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeAddDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFormRules"
        label-width="135px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="整改人" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="经销商" name="first">
              <el-cascader
                v-model="addForm.checkDealers"
                :options="dealerCodes"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
            <el-tab-pane label="小区经理" name="second">
              <el-cascader
                v-model="addForm.checkSmalls"
                :options="smallManagerList"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
            <el-tab-pane label="市场经理" name="three">
              <el-cascader
                v-model="addForm.checkMarkets"
                :options="marketManagerList"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="下发人" prop="handlerId">
          <el-select
            v-model="addForm.handlerId"
            placeholder="请选择下发人"
            filterable
          >
            <el-option
              v-for="item in haedUserList"
              :key="item.id"
              :label="item.nameCn"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="年月" prop="year">-->
        <!--          <el-date-picker-->
        <!--            v-model="addForm.year"-->
        <!--            type="year"-->
        <!--            value-format="yyyy"-->
        <!--            placeholder="选择年"-->
        <!--          >-->
        <!--          </el-date-picker>-->
        <!--          <el-date-picker-->
        <!--            v-model="addForm.month"-->
        <!--            type="month"-->
        <!--            value-format="M"-->
        <!--            placeholder="选择月"-->
        <!--          >-->
        <!--          </el-date-picker>-->
        <!--        </el-form-item>-->
        <el-form-item label="截止时间" prop="deadline">
          <el-date-picker
            v-model="addForm.deadline"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            default-time="23:59:59"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="整改类型" prop="typeId">
          <el-select
            v-model="addForm.typeId"
            placeholder="选择整改类型"
            filterable
          >
            <el-option
              v-for="item in specialTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="整改标题" prop="title">
          <el-input type="textarea" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="示例图片">
          <el-upload
            class="upload-demo"
            :on-remove="handleRemove"
            :before-upload="beforeUploadImg"
            :http-request="uploadImg"
            :file-list="addForm.imgList"
            action=""
            list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="小区经理审核">
          <el-switch v-model="addForm.isSmall"></el-switch>
        </el-form-item>
        <el-form-item label="整改内容" prop="content">
          <el-input type="textarea" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="关联问卷" prop="answerId">
          <el-select
            v-model="addForm.answerId"
            placeholder="请选择关联问卷"
            filterable
          >
            <el-option
              v-for="item in questionnaireList"
              :key="item.id"
              :label="item.questionName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置工单关注人" class="label-tip">
          <div slot="label" class="label-wrap">
            <!-- <div class="require-star">*</div> -->
            <div class="mr3">设置工单关注人</div>
            <el-tooltip placement="top">
              <div slot="content">
                <p>工单关注人工单进度邮件提醒设置：</p>
                <p>·工单添加提醒：每次新增的工单关注人会收到邮件通知。</p>
                <p>·截止日期提醒：在工单截止日期收到邮件提醒。</p>
              </div>
              <img src="@/assets/tip.png" alt="" />
            </el-tooltip>
          </div>
          <el-input
            v-model.trim="email"
            type="text"
            class="input-wrap"
            maxlength="50"
            @keyup.enter.native="submitemail"
            placeholder="请输入邮箱 (按回车确认)"
            clearable
          ></el-input>
          <el-button type="primary" class="ml20" @click="submitemail"
            >点击添加</el-button
          >
        </el-form-item>
        <div class="tag-father">
          <div
            class="tag-wrap"
            v-for="(item, index) in addForm.followEmails"
            :key="index"
          >
            <i class="el-icon-message email-icon"></i>
            <div>{{ item }}</div>
            <div
              class="close-icon"
              @click="addForm.followEmails.splice(index, 1)"
            >
              ×
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addHeadMatter">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="编辑工单关注人"
      :visible.sync="editDialogVisible"
      width="696px"
    >
      <el-form label-width="135px" @submit.stop.prevent>
        <el-form-item label="设置工单关注人" class="label-tip">
          <div slot="label" class="label-wrap">
            <!-- <div class="require-star">*</div> -->
            <div class="mr3">设置工单关注人</div>
            <el-tooltip placement="top">
              <div slot="content">
                <p>工单关注人工单进度邮件提醒设置：</p>
                <p>·工单添加提醒：每次新增的工单关注人会收到邮件通知。</p>
                <p>·截止日期提醒：在工单截止日期收到邮件提醒。</p>
              </div>
              <img src="@/assets/tip.png" alt="" />
            </el-tooltip>
          </div>
          <el-input
            v-model.trim="editemail"
            type="text"
            class="input-wrap"
            maxlength="50"
            @keyup.enter.native="entereditemail"
            placeholder="请输入邮箱 (按回车确认)"
            clearable
          ></el-input>
          <el-button type="primary" class="ml20" @click="entereditemail"
            >点击添加</el-button
          >
        </el-form-item>
        <div class="tag-father">
          <div
            class="tag-wrap"
            v-for="(item, index) in objectedit.followEmails"
            :key="index"
          >
            <i class="el-icon-message email-icon"></i>
            <div>{{ item }}</div>
            <div
              class="close-icon"
              @click="objectedit.followEmails.splice(index, 1)"
            >
              ×
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submiteditEmail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SaleOrAfter from "../common/SaleOrAfter.vue";
import { Api } from "@/assets/js/api.js";

function initQuery() {
  return {
    year: null,
    month: null,
    roleId: null,
  };
}

export default {
  components: { SaleOrAfter },
  data() {
    var checkDealerOrSmall = (rule, value, cb) => {
      if (
        this.addForm.checkSmalls.length === 0 &&
        this.addForm.checkDealers.length === 0 &&
        this.addForm.checkMarkets.length === 0
      ) {
        cb(new Error("请选择整改人"));
      }
      return cb();
    };
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error("请选择角色"));
      }
      return cb();
    };
    // 这里存放数据
    return {
      fullscreenLoading: false,
      fileList: [],
      exportDialogVisible: false,
      haedUserList: [],
      specialTypeList: [],
      smallManagerList: [],
      marketManagerList: [],
      dealerCodes: [],
      email: "",
      editemail: "",
      objectedit: {
        titile: "",
        matterOnlyId: "",
        followEmails: [],
      },
      addForm: {
        imgList: [],
        roleId: null,
        checkSmalls: [],
        checkDealers: [],
        checkMarkets: [],
        followEmails: [],
        handlerId: null,
        typeId: null,
        deadline: null,
        content: null,
        title: null,
        isSmall: null,
      },
      addFormRules: {
        year: [{ required: true, message: "请选择年月", trigger: "blur" }],
        content: [
          { required: true, message: "请填写整改内容", trigger: "blur" },
        ],
        target: [
          { required: true, message: "请填写整改目标", trigger: "blur" },
        ],
        deadline: [
          { required: true, message: "请选择截止时间", trigger: "blur" },
        ],
        handlerId: [
          { required: true, message: "请选择下发人", trigger: "blur" },
        ],
        typeId: [
          { required: true, message: "请选择整改类型", trigger: "blur" },
        ],
        role: [{ validator: checkRole, trigger: "blur" }],
        dealerOrSmall: [{ validator: checkDealerOrSmall, trigger: "blur" }],
      },
      addDialogVisible: false,
      editDialogVisible: false,
      loading: false,
      yearList: [
        2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030,
      ],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      headMatterContentList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: initQuery(),
      },
      total: null,
      questionnaireList: [],
    };
  },
  created() {
    this.getHeadMatterList(true);
    this.getTypeList();
    this.getHeadUserList();
  },
  methods: {
    handeditemail(row) {
      this.editemail = "";
      this.objectedit.matterOnlyId = row.matterOnlyId || "";
      this.objectedit.title = row.title || "";
      this.objectedit.followEmails = JSON.parse(
        JSON.stringify(row.followEmails || [])
      );
      this.editDialogVisible = true;
    },
    entereditemail() {
      if (this.objectedit.followEmails.length > 50) {
        this.$message.error("最多可添加50位关注人邮箱");
        return;
      }
      if (this.objectedit.followEmails.includes(this.editemail)) {
        this.$message.error("邮箱重复！");
        return;
      }
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (this.editemail) {
        if (emailRegex.test(this.editemail)) {
          if (this.editemail.indexOf("volvocars.com") > -1) {
            this.objectedit.followEmails.unshift(this.editemail);
            this.editemail = "";
          } else {
            this.$message.error("请添加volovo内部邮箱");
          }
        } else {
          this.$message.error("请输入正确的邮箱地址");
        }
      } else {
        this.$message.error("请输入邮箱地址");
      }
    },
    submitemail() {
      if (this.addForm.followEmails.length > 50) {
        this.$message.error("最多可添加50位关注人邮箱");
        return;
      }
      if (this.addForm.followEmails.includes(this.email)) {
        this.$message.error("邮箱重复！");
        return;
      }
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (this.email) {
        if (emailRegex.test(this.email)) {
          if (this.email.indexOf("volvocars.com") > -1) {
            this.addForm.followEmails.unshift(this.email);
            this.email = "";
          } else {
            this.$message.error("请添加volovo内部邮箱");
          }
        } else {
          this.$message.error("请输入正确的邮箱地址");
        }
      } else {
        this.$message.error("请输入邮箱地址");
      }
    },
    addRecord() {
      this.addDialogVisible = true;

      this.getQuestionnaireList();
    },
    async uploadSpecial(f) {
      const param = new FormData();
      param.append("file", f.file);
      this.fullscreenLoading = true;
      const { data: res } = await this.$http.post("ma/special/import", param);
      this.fullscreenLoading = false;

      if (res.code !== 0) {
        this.$message.error(res.msg);
      } else {
        this.$message.success("导入成功");
        this.fileList = [];
        this.exportDialogVisible = false;
        this.getHeadMatterList(true);
      }
    },
    handleRemove(file, fileList) {
      this.addForm.imgList = this.addForm.imgList.filter(
        (item) => item !== file
      );
    },
    async uploadImg(f) {
      const param = new FormData();
      param.append("file", f.file);

      const { data: res } = await this.$http.post("/ma/base/uploadImg", param);
      res.data.url = Api.ossDomain + res.data.url;
      this.addForm.imgList.push(res.data);
    },
    submitUpload() {
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    beforeUpload(file) {
      // 判断文件格式
      const name = file.name.split(".").pop();
      if (name !== "xlsx" && name !== "xls") {
        this.$message.error("只能选择excel文件");
        return false;
      }
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1 个文件");
    },
    beforeUploadImg(file) {
      // 判断文件格式
      const name = file.name.split(".").pop();
      if (name !== "png" && name !== "jpg") {
        this.$message.error("只能选择jpg或者png文件");
        return false;
      }
    },
    downLoadTempalte() {
      const url = Api.apiDomain + "/ma/special/downloadTemplate";
      window.open(url, "_self");
    },
    async submiteditEmail() {
      const { data: res } = await this.$http.post(
        `/ma/headMatter/update/follow?title=${this.objectedit.title}&matterOnlyId=${this.objectedit.matterOnlyId}`,
        this.objectedit.followEmails
      );
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.editemail = "";
        this.objectedit.matterOnlyId = "";
        this.objectedit.title = "";
        this.objectedit.followEmails = [];
        this.editDialogVisible = false;
        this.getHeadMatterList(true);
      } else {
        this.$message.error(res.msg);
      }
    },
    async addHeadMatter() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "/ma/headMatter/addHeadMatter",
            this.addForm
          );
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.addForm.imgList = [];
            this.$refs.addFormRef.resetFields();
            this.addForm.checkDealers = [];
            this.addForm.checkSmalls = [];
            this.addForm.followEmails = [];
            this.addForm.month = null;
            this.addDialogVisible = false;
            this.email = "";
            this.getHeadMatterList(true);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    handleClick() {
      this.addForm.checkDealers = [];
      this.addForm.checkSmalls = [];
      if (!this.addForm.roleId) {
        this.$message.info("请选择角色");
      } else {
        this.getDealerCodes();
        this.getSmallManagerList();
        this.getMarketManagerList();
      }
    },
    initAddRole(data) {
      this.addForm.roleId = data;
      this.getDealerCodes();
      this.getSmallManagerList();
      this.getMarketManagerList();
    },
    async getSmallManagerList() {
      const { data: res } = await this.$http.get("/ma/user/getSmallCascader", {
        params: { roleId: this.addForm.roleId },
      });
      this.smallManagerList = res.data;
    },
    async getMarketManagerList() {
      const { data: res } = await this.$http.get("/ma/user/getMarketCascader", {
        params: { roleId: this.addForm.roleId },
      });
      this.marketManagerList = res.data;
    },
    async getHeadUserList() {
      const { data: res } = await this.$http.get("/ma/user/headListForHead");
      this.haedUserList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$http.get("/ma/headMatter/type/list");
      this.specialTypeList = res.data;
    },
    async getDealerCodes() {
      const { data: res } = await this.$http.get(
        "/ma/base/bigSmallDealerList",
        { params: { roleId: this.addForm.roleId } }
      );
      this.dealerCodes = res.data;
    },
    closeAddDialog() {},
    async exportData(data) {
      const id = data.id;
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + `/ma/headMatter/exportData?id=${id}`;
      const { data: res } = await this.$http.get(url, this.addForm);
      if (res.code === -20048) {
        this.$message.error(res.msg);
      } else {
        window.open(url, "_self");
      }
    },
    selectYear() {
      this.queryInfo.query.month = null;
      this.getHeadMatterList(true);
    },
    initRole(data) {
      this.queryInfo.query.roleId = data;
      this.getHeadMatterList(true);
    },
    async getHeadMatterList(refresh = false) {
      if (refresh) {
        this.queryInfo.pageNum = 1;
      }

      this.loading = true;
      const { data: res } = await this.$http.post(
        "/ma/headMatter/content/list",
        this.queryInfo
      );
      this.loading = false;

      this.headMatterContentList = res.data.content;
      this.total = res.data.totalElements;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getHeadMatterList(true);
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getHeadMatterList();
    },
    async getQuestionnaireList() {
      const { data: res } = await this.$http.get("questionnaire/findAll");
      this.questionnaireList = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}

.el-table {
  margin-top: 10px;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}
.emaile-wrap {
  color: #409eff;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}
.edit-wrap {
  height: auto;
  width: 16px;
  height: 16px;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
.email-item {
  height: auto !important;
  overflow: hidden !important;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}
.label-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}
.label-tip {
  position: relative;
}
.tip-img {
  position: absolute;
  top: 0;
  left: 0;
}
.mr3 {
  margin-right: 3px;
}
.require-star {
  color: #f56c6c;
  margin-right: 4px;
}
.input-wrap {
  width: 300px;
  // margin-bottom: 10px;
}
.tag-father {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 135px;
  color: rgba(0, 0, 0, 0.92);
}
.tag-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  height: 26px;
  padding: 0 8px 1px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.close-icon {
  font-size: 20px;
  margin-left: 5px;
  position: relative;
  top: -1px;
  cursor: pointer;
}
.common-icon {
  color: rgba(61, 126, 254, 1);
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.email-icon {
  color: #409eff;
  margin-right: 5px;
  margin-top: 2px;
}
.ml20 {
  margin-left: 20px;
}
.va-s {
  vertical-align: sub;
}
</style>
