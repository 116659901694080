import { render, staticRenderFns } from "./HeadMatter.vue?vue&type=template&id=7ceadbeb&scoped=true"
import script from "./HeadMatter.vue?vue&type=script&lang=js"
export * from "./HeadMatter.vue?vue&type=script&lang=js"
import style0 from "./HeadMatter.vue?vue&type=style&index=0&id=7ceadbeb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ceadbeb",
  null
  
)

export default component.exports